import Skeleton from 'app/components/Skeleton';
import classNames from 'classnames';
import type { ReactNode } from 'react';

type DefaultProps = {
  title: string;
  value: string | number | null;
  className?: string;
  suffix?: string | ReactNode;
  loading?: boolean;
};

export const Value = ({
  title,
  value,
  loading,
  suffix,
  className,
}: DefaultProps) => {
  return (
    <div
      className={classNames(
        'rounded-[12px] border-[1.5px] border-carbon-200 bg-white p-4 flex flex-col gap-1 min-w-[250px] flex-1',
        className,
      )}
    >
      <h3 className="text-base font-medium">{title}</h3>
      <div className="flex flex-row gap-1.5 items-baseline flex-wrap">
        <span className="uppercase text-2xl font-bold">
          {loading ? (
            <div className="relative top-1">
              <Skeleton height="h-7" width="w-12" />
            </div>
          ) : (
            <>{value}</>
          )}
        </span>

        <span className="text-sm font-medium text-gray-500 whitespace-nowrap">
          {suffix}
        </span>
      </div>
    </div>
  );
};
