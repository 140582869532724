'use client';

import useMetricsCubeQuery from 'app/hooks/useMetricsCubeQuery';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import { useMemo } from 'react';

import YtdVolumeWidget from './YtdVolumeWidget';

export default function YtdTrends() {
  const t = useTranslations();

  const { data: ytmByTimeFrameData, isLoading: isLoadingYtmByTimeFrameData } =
    useMetricsCubeQuery({
      metric: 'FOOTPRINT',
      timeFrame: 'YTM',
    });

  const yearToDateString = useMemo(() => {
    const lastData = ytmByTimeFrameData?.[0];
    const previousData = ytmByTimeFrameData?.[1];

    if (!lastData || !previousData) {
      // No data available
      return t('YtdTrends.dateRange.noData');
    }

    return t('YtdTrends.dateRange.ytm', {
      lastStartMonth: dayjs(lastData.periodStartDate).format('MMMM'),
      lastEndMonth: dayjs(lastData.periodEndDate).format('MMM'),
      lastYear: dayjs(lastData.periodEndDate).format('YYYY'),
      previousStartMonth: dayjs(previousData.periodStartDate).format('MMM'),
      previousEndMonth: dayjs(previousData.periodEndDate).format('MMM'),
      previousYear: dayjs(previousData.periodEndDate).format('YYYY'),
    });
  }, [t, ytmByTimeFrameData]);

  const isLoading = isLoadingYtmByTimeFrameData;

  return (
    <section className="w-full">
      <h2 className="text-carbon-800 text-base font-medium">
        {t('YtdTrends.keyFigures')}{' '}
      </h2>
      <h3 className="text-carbon-500 text-xs font-medium mb-3">
        {yearToDateString}
      </h3>

      <div className="w-full flex flex-row flex-wrap gap-2 [align-items:stretch] [justify-content:stretch]">
        <YtdVolumeWidget
          metric="FOOTPRINT"
          isLoading={isLoading}
          period={ytmByTimeFrameData?.[0]?.period}
          compareToPeriod={ytmByTimeFrameData?.[1]?.period}
        />
        <YtdVolumeWidget
          metric="composite_FOOTPRINT_PER_UNIT"
          isLoading={isLoading}
          period={ytmByTimeFrameData?.[0]?.period}
          compareToPeriod={ytmByTimeFrameData?.[1]?.period}
        />
        <YtdVolumeWidget
          metric="UNITS_MEASURED"
          isLoading={isLoading}
          period={ytmByTimeFrameData?.[0]?.period}
          compareToPeriod={ytmByTimeFrameData?.[1]?.period}
        />
      </div>
    </section>
  );
}
