import type {
  MetricsCubeQueryData,
  MetricsCubeQueryOptions,
} from '@carbonfact/shared/src/types/platform/metrics-cube';
import { formatCount, formatPercent } from 'app/lib/formatNumber';
import { upperFirst } from 'lodash';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo } from 'react';

interface MetricsCubeFormattingUtils {
  getMetricValue: (d: MetricsCubeQueryData) => number;
  formatMetricValue: (v: number) => string;
  metricSuffix: string;
  formatMetricsCubeString: (kayaString?: string | null) => string;
  getMetricOrDimensionDescription: (
    kayaString?: string | null,
  ) => string | undefined;
}

// A collection of printing and formatting utilities to display metrics-cube data
export default function useMetricsCubeFormattingUtils(
  options: MetricsCubeQueryOptions,
): MetricsCubeFormattingUtils {
  const t = useTranslations();

  const [getMetricValue, formatMetricValue, metricSuffix] = useMemo<
    [
      MetricsCubeFormattingUtils['getMetricValue'],
      MetricsCubeFormattingUtils['formatMetricValue'],
      MetricsCubeFormattingUtils['metricSuffix'],
    ]
  >(() => {
    switch (options.metric) {
      case 'FOOTPRINT':
        return [
          (d) => d.metricValue / 1000, // convert kgCO2e to tCO2e
          (v) => formatCount(v),
          'tCO2e',
        ];
      case 'UNITS':
      case 'UNITS_MEASURED':
        return [(d) => d.metricValue, (v) => formatCount(v), 'units'];
      case 'MASS':
      case 'MASS_WITH_LOSS':
      case 'MASS_MEASURED':
      case 'MASS_WITH_LOSS_MEASURED':
        return [
          (d) => d.metricValue / 1000, // convert grams to kg
          (v) => formatCount(v),
          'kg',
        ];
      case 'UNCERTAINTY':
        return [
          (d) => d.metricValue / 1000, // convert kgCO2e to tCO2e
          (v) => formatCount(v),
          'tCO2e',
        ];
      case 'composite_MASS_PER_UNIT':
        return [(d) => d.metricValue, (v) => formatCount(v), 'kg/unit'];
      case 'composite_FOOTPRINT_PER_UNIT':
        return [(d) => d.metricValue, (v) => formatCount(v), 'kgCO2e/unit'];
      case 'composite_FOOTPRINT_PER_MASS':
        return [
          (d) => d.metricValue * 1000, // grams to kg
          (v) => formatCount(v),
          'kgCO2e/kg',
        ];
      case 'composite_UNITS_SHARE':
      case 'composite_UNITS_MEASURED_SHARE':
      case 'composite_FOOTPRINT_SHARE':
      case 'composite_MASS_SHARE':
      case 'composite_MASS_MEASURED_SHARE':
      case 'composite_MASS_WITH_LOSS_SHARE':
      case 'composite_MASS_WITH_LOSS_MEASURED_SHARE':
        return [(d) => d.metricValue, (v) => formatPercent(v), '%'];
      default:
        return [(d) => d.metricValue, (v) => formatCount(v), 'metric'];
    }
  }, [options.metric]);

  const formatMetricsCubeString = useCallback<
    MetricsCubeFormattingUtils['formatMetricsCubeString']
  >(
    (kayaString) => {
      if (!kayaString) return 'unknown';

      const normalizedKayaString = kayaString
        .toLowerCase()
        .trim()
        .replaceAll('composite_', ''); // marker for composite metrics

      // TECHNICAL-DEBT: This is a temporary solution to translate materials.
      // A better one would be to pass a specific namespace when translating

      const prefixesToCheck = ['explorerDimensions', 'materials'];
      for (const prefix of prefixesToCheck) {
        let stringToCheck = normalizedKayaString;
        if (prefix === 'materials') {
          stringToCheck = normalizedKayaString.toUpperCase();
        } else {
          stringToCheck = `${normalizedKayaString}.name`;
        }
        const translation = t(`${prefix}.${stringToCheck}`);
        const wasTranslated = translation !== `${prefix}.${stringToCheck}`;

        if (wasTranslated) {
          return upperFirst(translation);
        }
      }

      let translatedString = t(
        `explorerDimensions.${normalizedKayaString}.name`,
      );

      const wasTranslated =
        translatedString !== `explorerDimensions.${normalizedKayaString}.name`;

      if (!wasTranslated) {
        translatedString = upperFirst(
          normalizedKayaString
            .replaceAll('_', ' ')
            .replaceAll('-', ' ')
            .replaceAll('#', ' ')
            .replaceAll('slug', ''),
        );
      }
      return translatedString;
    },
    [t],
  );

  const getMetricOrDimensionDescription = useCallback<
    MetricsCubeFormattingUtils['getMetricOrDimensionDescription']
  >(
    (kayaString) => {
      if (!kayaString) return undefined;

      const normalizedKayaString = kayaString.toLowerCase().trim();

      const translation = t(
        `explorerDimensions.${normalizedKayaString}.description`,
      );
      const wasTranslated =
        translation !==
        `explorerDimensions.${normalizedKayaString}.description`;

      if (!wasTranslated) return '';

      return translation;
    },
    [t],
  );

  return {
    getMetricValue,
    formatMetricValue,
    metricSuffix,
    formatMetricsCubeString,
    getMetricOrDimensionDescription,
  };
}
